<template>
  <div class="baik-login">
    <div class="bl-logo w-100 d-flex justify-content-center pt-4">
      <img src="@/assets/images/logo.png" alt="" class="w-25" />
    </div>
    <div
      class="bl-title w-100 d-flex mb-3 text-primary justify-content-center align-items-center text-center py-2"
    >
      Aplikasi Karyawan <br />
      KSPPS BAIK
    </div>
    <div class="bl-image">
      <img
        src="@/assets/images/laptop.png"
        alt=""
        class="img-fluid w-50 d-flex justify-content-center mx-auto pt-1 pb-3"
      />
    </div>
    <div class="bl-form w-75 mx-auto my-3">
      <b-form @submit.prevent="doLogin">
        <b-form-group class="baik-form mb-3 text-center text-light">
          <b-input
            size="md"
            placeholder="Username"
            v-model="form.data.username"
          />
        </b-form-group>
        <b-form-group class="baik-form mb-4 text-center text-light">
          <b-input
            size="md"
            placeholder="Password"
            type="password"
            v-model="form.data.password"
          />
        </b-form-group>
        <div
          class="d-flex justify-content-center w-100 align-items-center flex-column"
        >
          <b-button
            variant="info"
            size="lg"
            class="w-100 d-flex text-center justify-content-center baik-btn"
            style="border-radius: 30px"
            type="submit"
            :disabled="form.loading"
            >{{ form.loading ? "Mohon Tunggu..." : "Masuk" }}</b-button
          >
          <!-- <p class="text-center mt-2 text-dark">
            Lupa password ? <br />
            <router-link to="/forgot" class="text-dark"
              >Klik disini</router-link
            >
          </p> -->
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      form: {
        data: {
          username: "",
          password: "",
        },
        loading: false,
      },
    };
  },
  watch: {
    user(val) {
      let user = val;
      if (user && user.token && user.data.user_id) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["login"]),
    async doLogin() {
      this.form.loading = true;
      let url = "/auth/login";
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      try {
        let req = await axios.post(url, payload);
        if (req.data.status) {
          this.form.loading = false;
          let session = req.data;
          this.login(session);
        } else {
          this.alert("danger", "Error", req.data.msg);
          this.form.loading = false;
        }
      } catch (error) {
        this.alert("danger", "Error", error.message);
      }
    },
    alert(type, title, msg) {
      this.$bvToast.toast(msg, {
        title: title,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: type,
        appendToast: true,
      });
    },
  },
};
</script>